@font-face
	font-family: "svgfont"
	src: url('fonts/svgfont.eot')
	src: url('fonts/svgfont.eot?#iefix') format('eot'), url('fonts/svgfont.woff') format('woff'), url('fonts/svgfont.woff2') format('woff2'), url('fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-close: "\EA01"
$icon-wss: "\EA02"
$icon-slider-arrow: "\EA03"
$icon-slider-arrow-left: "\EA04"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-close:before
	content: $icon-close
.icon-wss:before
	content: $icon-wss
.icon-slider-arrow:before
	content: $icon-slider-arrow
.icon-slider-arrow-left:before
	content: $icon-slider-arrow-left