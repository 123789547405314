.header{
	border-bottom: 1.5px solid $colorBlack;
	padding:  pxToVW1920(30px) 0 ;
	margin-bottom:  pxToVW1920(36px);
	@media screen and (max-width: 1439px){
		padding: 14px 0 ;
		border-bottom: 1px solid $colorBlack;
		margin-bottom: 24px;
	}
	@media screen and (max-width: 991px){
		padding: 24px 0 ;
		margin-bottom: 32px;
	}
	@media screen and (max-width: 767px){
		padding: 6px 0 ;
		margin-bottom: 16px;
	}
	&__content{
		height: pxToVW1920(42px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media screen and (max-width: 1620px){
			height: 42px;
		}
	}
	&__icon{
		padding-right: 48px;
		border-right: 1.5px solid $colorBlack;
		@media screen and (max-width: 1439px){
			padding-right: 32px;
			border-right: 1px solid $colorBlack;
		}
		@media screen and (max-width: 991px){
			padding-right: 28px;
		}
		@media screen and (max-width: 767px){
			padding-right: 18px;
		}
	}
	.logo-svg{
		width:  pxToVW1920(150px);
		height:  pxToVW1920(42px);
		@media screen and (max-width: 1439px){
			width: 100px;
			height: 28px;
		}
		@media screen and (max-width: 991px){
			width: 122px;
			height: 34px;
		}
		@media screen and (max-width: 767px){
			width: 86px;
			height: 24px;
		}
	}

}


