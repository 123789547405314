@use "sass:math";

$colorGrey: #666;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorGreen: #4ab020;
$colorLightGreen: #99c71f;
$colorDarkGreen: #648300;
$colorRed: #b01e1e;
$colorLightRed: #ce7f7f;
$colorDarkRed: #881616;

$colorWhite: #fff;
$colorBlack: #141414;
$colorBgWhite: #F5F5F5;
$colorDarkBlue: #002432;
$colorGray3: #F0F0F0;
$colorViolet: #9C6FCC;

@function pxToRem($value) {
	@return math.div($value, 16px) * 1rem;
}

@function pxToVW1920($value) {
	@return math.div($value, 1920px) * 100vw;
}


html,
body {
	height: 100%;
	background: $colorBgWhite;
	color: $colorBlack;font-size: 21px;
	font-family: "Inter Tight", sans-serif;
	line-height: 140%;
}

* {
	outline: none;
	box-sizing: border-box;
}

input,
textarea,
button,
select,
div,
a {
	-webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
}

img {
	vertical-align: top;
	max-width: 100%;
}

a {
	text-decoration: none;
	transition: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}

p, li {
	margin: 0;
	font-size:  pxToVW1920(21px);
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin-bottom:  pxToVW1920(16px);
	@media screen and (max-width: 1439px){
		font-size: 14px;
		letter-spacing: 0.56px;
	}
	@media screen and (max-width: 991px){
		font-size: 16px;
		letter-spacing: 0.64px;
	}
	@media screen and (max-width: 767px){
		font-size: 14px;
		letter-spacing: 0.56px;
	}
}

button {
	outline: none;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

[class^="icon-"],
[class*=" icon-"] {
	position: relative;
	vertical-align: middle;
}

.wrapper {
	position: relative;
	min-width: 320px;
	min-height: 100%;
	overflow: clip;
}
.container{
	width: 100%;
	padding: 0 pxToVW1920(60px);
	margin-right: auto;
	margin-left: auto;
	@media screen and (max-width: 1439px){
		padding: 0 40px;
	}
	@media screen and (max-width: 480px){
		padding: 0 16px;
	}
}

.collapse{
	border-top: 1.5px solid $colorBlack;
	@media screen and (max-width: 1439px){
		border-top: 1px solid $colorBlack;
	}
	&__button-wrap{
		width: 100%;
		position: relative;
		background: $colorBgWhite;
		padding: 19px 0;
		@media screen and (max-width: 1439px){
			padding: 8px 0 8px 0;
		}
		@media screen and (max-width: 991px){
			padding: 18px 0;
		}
		@media screen and (max-width: 767px){
			padding: 8px 0 8px 0;
		}
	}
	&__button{
		font-size:  pxToVW1920(24px);
		font-style: normal;
		font-weight: 500;
		line-height: 110%;
		text-transform: uppercase;
		background: none;
		padding: 0;
		width: 100%;
		text-align: left;
		position: relative;
		color: $colorBlack;
		@media screen and (max-width: 1620px){
			font-size: 22px;
		}
		@media screen and (max-width: 1439px){
			font-size: 16px;
		}
		@media screen and (max-width: 991px){
			font-size: 22px;
		}
		@media screen and (max-width: 767px){
			font-size: 16px;
		}
	}
	&__button-icon{
		width: 18px;
		height: 18px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);

		@media screen and (max-width: 1439px){
			width: 12px;
			height: 12px;
		}
		@media screen and (max-width: 991px){
			width: 18px;
			height: 18px;
		}
		@media screen and (max-width: 767px){
			width: 12px;
			height: 12px;
		}
		&::after, &::before{
			content: " ";
			position: absolute;
			width: 18px;
			height: 2px;
			background: $colorBlack;
			top: 50%;
			transform: translate(0, -50%);
			left: 0;
			@media screen and (max-width: 1439px){
				width: 12px;
				height: 1.5px;
			}
			@media screen and (max-width: 991px){
				width: 18px;
			}
			@media screen and (max-width: 767px){
				width: 12px;
			}
		}
		&::before{
			transform:  translate(0, -50%) rotate(90deg);
			transition: all  .4s ease;
		}

	}

	&__content{
		transition: max-height .3s ease, .2s opacity ease;
		opacity: 0;
		overflow: hidden;
		max-height: 0;
		background: $colorBgWhite;
		font-size: 21px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		letter-spacing: 0.84px;
		@media screen and (max-width: 1439px){
			font-size: 14px;
			letter-spacing: 0.56px;
		}
		@media screen and (max-width: 991px){
			font-size: 16px;
			letter-spacing: 0.64px;
		}

		li{
			margin-bottom:  pxToVW1920(12px);
		}
		ul{
			list-style-type: square;
		}
	}
	&.open{
		.collapse__content{
			opacity: 1;
			overflow: visible;
			margin-bottom: 26px;
			transition: max-height .3s ease, .2s opacity ease;
		}
		.collapse__button-icon{
			&::before{
				transform: rotate(0deg);
			}
		}
	}
}


// custom select  //
.select-container {
	position: relative;
	margin: 0 auto;
	width: pxToVW1920(48px) ;
	@media screen and (max-width: 1439px){
		width: 32px;
	}
}

.select-container .language-select {
	position: relative;
	height: pxToVW1920(40px);
	@media screen and (max-width: 1439px){
		height: 20px;
	}
}

.select-container .language-select::after {
	position: absolute;
	content: "";
	width:  pxToVW1920(12px);
	height: pxToVW1920(12px);
	top: 50%;
	right: 1px;
	transform: translateY(-50%) rotate(0deg);
	cursor: pointer;
	transition: border-color 0.4s;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M10.5 3H1.5L6 10.5L10.5 3Z' fill='%23141414'/%3E%3C/svg%3E");
	background-position: center;
	background-size: 100%;
	@media screen and (max-width: 1439px){
		width: 8px;
		height: 8px;
	}
}

.select-container.active .select::after {
	transform: translateY(-50%) rotate(180deg);
}
.select-container .language-select input {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	background: none;
	outline: none;
	border: none;
	font-size: pxToVW1920(18px);
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 21.6px */
	letter-spacing: pxToVW1920(0.36px);
	text-transform: uppercase;
	color: $colorBlack;
	cursor: pointer;
	pointer-events: none;
	opacity: 1;
	@media screen and (max-width: 1439px){
		font-size: 12px;
		letter-spacing: 0.24px;
	}
	&::placeholder{
		font-size: pxToVW1920(18px);
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 21.6px */
		letter-spacing: pxToVW1920(0.36px);
		text-transform: uppercase;
		color: $colorBlack;
		@media screen and (max-width: 1439px){
			font-size: 12px;
			letter-spacing: 0.24px;
		}
	}
}
.select-container .select-option-container {
	position: absolute;
	top: pxToVW1920(40px);
	height: 0;
	overflow: hidden;
	transition: 0.4s;
	background: $colorWhite;
	padding: 0 pxToVW1920(16px) ;
	@media screen and (max-width: 1439px){
		top: 20px;
	}
	@media screen and (max-width: 1199px){
		padding: 0 12px;
	}
}

.select-container.active .select-option-container {
	height: auto;
	padding: pxToVW1920(16px) ;
	@media screen and (max-width: 1199px){
		padding: 12px;
	}
}
.select-option-container label, .select-option-container .language-option{
	padding: 0;
	margin: 0;
	line-height: 100%;
	font-size: 10px;
}
.select-option-container .language-option{
	margin-bottom: pxToVW1920(10px);

	@media screen and (max-width: 1439px){
		margin-bottom: 10px;
	}
	&:last-child{
		margin-bottom: 0;
	}
}

.select-option-container a {
	font-size: pxToVW1920(18px);
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 21.6px */
	letter-spacing: pxToVW1920(0.36px);
	text-transform: uppercase;
	margin-bottom: pxToVW1920(10px);
	color: $colorDarkBlue;
	&:last-child{
		margin-bottom: 0;
	}

	@media screen and (max-width: 1439px){
		font-size: 12px;
		letter-spacing: 0.24px;
	}
}
// end custom select  //
