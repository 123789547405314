.productBenefits{
	height:  pxToVW1920(1425px);
	position: relative;
	@media screen and (max-width: 1620px){
		height: 1250px;
	}
	@media screen and (max-width: 1439px){
		height: 950px;
	}
	@media screen and (max-width: 991px){
		height: 992px;
	}
	@media screen and (max-width: 767px){
		height: auto;
	}

	&.violet{
		background: $colorDarkBlue;;
	}

	&__bg-img{
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		@media screen and (max-width: 991px){
			height: 77%;
		}
		@media screen and (max-width: 767px){
			height: auto;
		}
	}
	h2{
		font-size:  pxToVW1920(108px);
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 108px */
		text-transform: uppercase;
		color: $colorWhite;
		max-width: 70%;
		margin: 0 auto;
		text-align: center;
		@media screen and (max-width: 1439px){
			font-size: 72px;
		}
		@media screen and (max-width: 991px){
			font-size: 80px;
			max-width: 100%;
		}
		@media screen and (max-width: 767px){
			font-size: 40px;
		}
	}
	&__wrap{
		padding: pxToVW1920(204px) 0 0 0;
		@media screen and (max-width: 1439px){
			padding: 136px 0 0 0;
		}
		@media screen and (max-width: 767px){
			padding: 80px 0 0 0;
		}
	}
	&__content{
		position: relative;
		display: flex;
		justify-content: space-between;
		//width: 75.5%;
		width: 79.5%;
		margin: 0 auto;
		z-index: 1;
		margin-top: 109px;
		height:  pxToVW1920(670px);
		gap: pxToVW1920(260px);
		@media screen and (max-width: 1620px){
			height: 550px;
			gap: 220px;
		}
		@media screen and (max-width: 1439px){
			height: 450px;
			margin-top: 72px;
			//width: 74%;
			width: 84%;
		}
		@media screen and (max-width: 1280px){
			width: 94%;
		}
		@media screen and (max-width: 991px){
			flex-direction: column-reverse;
			align-items: end;
			width: 100%;
			margin-right: auto;
			margin-right: 0;
			margin-top: 80px;
			gap: 0;
		}
		@media screen and (max-width: 767px){
			width: 100%;
			margin-top: 48px;
			height: auto;
		}

	}
	&__img-wrap{
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
		height:  pxToVW1920(670px);
		width: auto;
		@media screen and (max-width: 1620px){
			height: 550px;
		}
		@media screen and (max-width: 1439px){
			height: 450px;
		}
		@media screen and (max-width: 991px){
			left: 0;
			height: 480px;
			transform: translate(0, 0);
		}
		@media screen and (max-width: 767px){
			position: static;
			width: auto;
			margin: 26px auto;
			height: 69vw;
		}
		@media screen and (max-width: 480px){
			height: 72vw;
		}
		img{
			height: 100%;
			width: auto;
		}
	}
	&__benefits-right, &__benefits-left{
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		//gap: 169px;
		gap: 100px;
		@media screen and (max-width: 1439px){
			//gap: 112px;
			gap: 80px;
		}
		@media screen and (max-width: 991px){
			width: calc(100% - 320px);
			gap: 24px;
			.productBenefits__benefit{
				padding-right: 0;
				padding-left: 40%;
			}
		}
		@media screen and (max-width: 767px){
			width: 100%;
			gap: 20px;
		}
	}
	&__benefits-right{
		margin-top: auto;
		padding-bottom:  pxToVW1920(80px);
		@media screen and (max-width: 1439px){
			padding-bottom: 58px;
		}
		@media screen and (max-width: 991px){
			padding-top: 101px;
			margin-top: 0;
			padding-bottom: 0;
		}
		@media screen and (max-width: 767px){

			padding-top: 49px;
		}
		.productBenefits__benefit{
			padding-left: 32%;
			@media screen and (max-width: 1439px){
				padding-left: 31%;
			}
			@media screen and (max-width: 991px){
				padding-left: 30%;
			}
			@media screen and (max-width: 767px){
				padding-left: 0;
				&:last-child{
					//border: none;
				}
			}
		}
	}
	&__benefits-left{
		padding-top:  pxToVW1920(94px);
		@media screen and (max-width: 1439px){
			padding-top: 65px;
		}
		@media screen and (max-width: 991px){
			padding-top: 24px;
		}
		@media screen and (max-width: 767px){
			padding-top: 20px;
		}
		.productBenefits__benefit{
			padding-right: 32%;
			@media screen and (max-width: 1439px){
				padding-right: 31%;
			}
			@media screen and (max-width: 991px){
				padding-right: 0;
				padding-left: 30%;
			}
			@media screen and (max-width: 767px){
				padding-left: 0;
			}
		}
	}
	&__benefit-img{
		width: 100%;
		display: flex;
		justify-content: end;
		padding-right:  pxToVW1920(70px);
		@media screen and (max-width: 991px){
			padding-right: 50px;
		}
		@media screen and (max-width: 767px){
			justify-content: start;
		}
		img{
			width: pxToVW1920(270px);
			height: auto;
			@media screen and (max-width: 991px){
				width: 150px;
			}
			@media screen and (max-width: 767px){
				width: 180px;
			}
		}
	}
	&__benefit{
		padding-bottom:  pxToVW1920(26px);
		border-bottom: 1.5px solid $colorWhite;
		display: flex;
		gap: 28px;
		align-items: center;
		width: 100%;
		@media screen and (max-width: 1439px){
			gap: 20px;
			padding-bottom: 18px;
			border-bottom: 1px solid $colorWhite;
		}
		@media screen and (max-width: 991px){
			gap: 16px;
			//justify-content: end;
		}
		@media screen and (max-width: 767px){
			gap: 12px;
			padding-bottom: 20px;
			justify-content: left;
		}
		p{
			font-size:  pxToVW1920(21px);
			font-style: normal;
			font-weight: 400;
			line-height: 140%;
			letter-spacing:  pxToVW1920(0.84px);
			color: $colorWhite;
			margin: 0;

			@media screen and (max-width: 1439px){
				font-size: 14px;
				letter-spacing: 0.56px;
			}
			@media screen and (max-width: 767px){
				font-size: 14px;
				letter-spacing: 0.56px;
			}
		}
	}
	&_icon-wrap{
		border-radius: 50%;
		background: $colorWhite;
		width:  pxToVW1920(60px);
		min-width:  pxToVW1920(60px);
		height:  pxToVW1920(60px);
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 1439px){
			width: 40px;
			height: 40px;
			min-width: 40px;
		}
		@media screen and (max-width: 767px){
			width: 32px;
			height: 32px;
			min-width: 32px;
		}

		svg, img{
			height:  pxToVW1920(54px);
			width: auto;
			object-fit: scale-down;
			@media screen and (max-width: 1439px){
				height: 34px;
			}
			@media screen and (max-width: 767px){
				height: 28px;
			}
		}
	}
	.container {
		@media screen and (max-width: 991px){
			padding: 0px 38px;
		}
		@media screen and (max-width: 480px){
			padding: 0 16px;
		}
	}
}
