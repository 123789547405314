.product{
	margin: pxToVW1920(36px) 0 pxToVW1920(204px) 0;
	@media screen and (max-width: 1439px){
		margin: 24px 0 134px 0;
		padding-top: 36px;
	}
	@media screen and (max-width: 991px){
		margin: 32px 0 134px 0;
		padding-top: 32px;
	}
	@media screen and (max-width: 767px){
		margin: 16px 0 80px 0;
		padding-top: 16px;
	}

	&-logo{
		margin-bottom: 40px;
		@media screen and (max-width: 767px){
			margin-bottom: 18px;
		}
		img{
			width: 300px;
			height: auto;
			@media screen and (max-width: 767px){
				width: 240px;
			}
		}
	}
	&__ingredients-wrap{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@media screen and (max-width: 767px){
			flex-direction: column;
		}
		div:first-child{
			width: 70%;
			@media screen and (max-width: 767px){
				width: 100%;
			}
		}
		div:last-child{
			img{
				object-fit: scale-down;
				max-width: 100%;
				max-height: 100%;
			}
			@media screen and (max-width: 767px){
				width: 100%;
				display: flex;
				justify-content: center;
				padding-top: 12px;
			}
		}
	}
	&__wrap{
		display: flex;
		@media screen and (max-width: 991px){
			flex-direction: column;
			gap: 80px;
		}
		@media screen and (max-width: 767px){
			gap: 40px;
		}

	}
	&__gallery{
		width: 50%;
		display: flex;
		flex-direction: row-reverse;
		gap: pxToVW1920(24px);
		height: 42.5vw;
		padding-right: 10px;
		position: sticky;
		top: 10px;

		@media screen and (max-width: 1620px){
			gap: 24px;
		}
		@media screen and (max-width: 1439px){
			gap: 16px;
		}
		@media screen and (max-width: 991px){
			width: 100%;
			height: 81.2vw;
			padding-right: 0;
			position: static;
		}
		@media screen and (max-width: 767px){
			flex-direction: column;
			height: auto;
			gap: 12px;
		}


		img{
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__main-image{
		width: 50%;
		height: 42.5vw;
		padding-right: 10px;
		position: sticky;
		top: 10px;

		@media screen and (max-width: 991px){
			width: 100%;
			height: 81.2vw;
			padding-right: 0;
			position: static;
		}
		@media screen and (max-width: 767px){
			height: auto;
			gap: 12px;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: scale-down;
		}
	}
	.gallery-top{
		flex: 1;
		@media screen and (max-width: 767px){
			flex: auto;
			width: 100%;
			//height: 464px;
			height: 98vw;
		}
		@media screen and (max-width: 480px){
			//height: 314px;
		}
	}
	&__brand-logo{
		position: absolute;
		z-index: 2;
		border-radius: 124.142px;
		height: 72px;
		width: 108px;
		padding: 11px;
		background: $colorWhite;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 40px;
		left: 40px;

		@media screen and (max-width: 1439px){
			border-radius: 82.761px;
			height: 48px;
			width: 72px;
			padding: 7.5px;
			top: 24px;
			left: 24px;
		}
		@media screen and (max-width: 991px){
			border-radius: 96.182px;
			height: 56px;
			width: 83px;
			padding: 8.8px;
			top: 31px;
			left: 31px;
		}
		@media screen and (max-width: 767px){
			display: none;
		}

		img{
			height: 100%;
			width: auto;
		}
	}
	.gallery-thumbs{
		width: 6.7vw;
		@media screen and (max-width: 1439px){
			width: 85px;
		}
		@media screen and (max-width: 991px){
			width: 102px;
		}
		@media screen and (max-width: 767px){
			width: 100%;
			height:22.7vw;
		}

		.swiper-slide{
			width: 100%;
			@media screen and (max-width: 1439px){
			}
			@media screen and (max-width: 991px){
			}
			@media screen and (max-width: 767px){
				width: 20vw !important;
			}

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.swiper-slide-thumb-active{
		border: 1px solid $colorBlack;
	}
	.swiper-button-prev{
		left: pxToVW1920(40px);
		@media screen and (max-width: 1439px){
			left: 24px;
			top: calc(50% + 6px);
		}
		@media screen and (max-width: 991px){
			left: 32px;
			top: 50%;
		}
		@media screen and (max-width: 767px){
			left: 16px;
			top: calc(50% + 7px);
		}
	}
	.swiper-button-next{
		right: pxToVW1920(40px);
		@media screen and (max-width: 1439px){
			right: 24px;
			top: calc(50% + 6px);

		}
		@media screen and (max-width: 991px){
			right: 32px;
			top: 50%;
		}
		@media screen and (max-width: 767px){
			right: 16px;
			top: calc(50% + 7px);
		}
	}

	&__info{
		width: 50%;
		padding-left: 50px;
		display: flex;
		flex-direction: column;
		//justify-content: space-between;
		@media screen and (max-width: 1439px){
			padding-left: 30px;
		}
		@media screen and (max-width: 991px){
			width: 100%;
			padding-left: 0;
		}
	}
	h1{
		font-size:  pxToVW1920(48px);
		font-style: normal;
		font-weight: 500;
		line-height: 110%; /* 52.8px */
		letter-spacing: -0.48px;
		text-transform: uppercase;
		margin-bottom: pxToVW1920(40px);
		@media screen and (max-width: 1439px){
			font-size: 32px;
			letter-spacing: -0.32px;
			margin-bottom: 24px;
		}
		@media screen and (max-width: 767px){
			font-size: 24px;
			letter-spacing: normal;
			margin-bottom: 16px;
		}
	}
	h2{
		font-size:  pxToVW1920(48px);
		font-style: normal;
		font-weight: 500;
		line-height: 110%; /* 52.8px */
		letter-spacing: -0.48px;
		text-transform: uppercase;
		margin-bottom: pxToVW1920(40px);
		@media screen and (max-width: 1439px){
			font-size: 32px;
			letter-spacing: -0.32px;
			margin-bottom: 24px;
		}
		@media screen and (max-width: 767px){
			font-size: 24px;
			letter-spacing: normal;
			margin-bottom: 16px;
		}
	}
	&__short-desc{
		font-size: pxToVW1920(21px);
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		letter-spacing: pxToVW1920(0.84px);
		width: 85%;
		margin-bottom: pxToVW1920(170px);
		@media screen and (max-width: 1620px){

		}
		@media screen and (max-width: 1439px){
			font-size: 14px;
			letter-spacing: 0.56px;
			margin-bottom: 122px;
		}
		@media screen and (max-width: 991px){
			margin-bottom: 64px;
			width: 100%;
		}
		@media screen and (max-width: 767px){
			font-size: 14px;
			letter-spacing: 0.56px;
			margin-bottom: 32px;
		}
	}
	&__collapse-wrap{
		border-bottom: 1.5px solid $colorBlack;
		background: $colorBgWhite;

		@media screen and (max-width: 1439px){
			border-bottom: 1px solid $colorBlack;
		}
	}

}
.product-banner{
	.product__info {
		padding-top: 6vw;
		@media screen and (max-width: 991px){
			padding-top: 0;
		}
	}
}
