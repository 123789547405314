.footer{
	background: $colorDarkBlue;
	color: rgb($colorWhite, .8);
	.container{
		@media screen and (max-width: 991px){
			width: 100%;
			padding: 0;
		}
	}
	&__content{
		display: flex;
		@media screen and (max-width: 991px){
			flex-direction: column;
		}
	}
	&__info, &__links{
		padding: pxToVW1920(168px) 0 pxToVW1920(60px) 0;
		@media screen and (max-width: 1439px){
			padding: 113px 0 40px 0;
		}
		@media screen and (max-width: 1199px){
			padding: 60px 0 40px 0;
		}
	}
	&__links{
		//padding-left: 132px;
		padding-left: 7.5%;
		width: 67%;
		@media screen and (max-width: 1439px){
			padding-left: 88px;
		}
		@media screen and (max-width: 1199px){
			padding-left: 34px;
			width: 74%;
		}
		@media screen and (max-width: 991px){
			width: 100%;
			padding: 72px 40px 40px 40px;
		}
		@media screen and (max-width: 767px){
			padding: 48px 16px 20px 16px;
		}
	}
	&__info{
		border-right: 1.5px solid $colorWhite;
		padding-right: 98px;
		width: 33%;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: 1439px){
			padding-right: 54px;
		}
		@media screen and (max-width: 1199px){
			padding-right: 10px;
			width: 26%;
		}
		@media screen and (max-width: 991px){
			width: 100%;
			padding: 104px 40px 72px 40px;
			border-right: none;
			border-bottom: 1px solid $colorWhite;
		}
		@media screen and (max-width: 767px){
			padding: 56px 16px 48px 16px;
		}
	}
	&__info-btns{
		margin-top: auto;
		display: flex;
		flex-wrap: wrap;
		gap:  pxToVW1920(14px);
		@media screen and (max-width: 1439px){
			gap: 10px;
		}
		@media screen and (max-width: 1199px){
			gap: 12px;
		}
		@media screen and (max-width: 767px){
			gap: 10px;
		}
		.btn{
			@media screen and (max-width: 767px){
				padding: 8px 16px;
				font-size: 12px;
				letter-spacing: 0.24px;
				height: 32px;
			}
		}
	}
	.logo-svg{
		width:  pxToVW1920(378px);
		height:  pxToVW1920(108px);
		margin-bottom:  pxToVW1920(72px);
		@media screen and (max-width: 1439px){
			width: 252px;
			height: 72px;
			margin-bottom: 48px;
		}
		@media screen and (max-width: 1199px){
			width: 168px;
			height: 48px;
		}
		@media screen and (max-width: 991px){
			width: 280px;
			height: 80px;
			margin-bottom: 48px;
		}
		@media screen and (max-width: 767px){
			width: 168px;
			height: 48px;
			margin-bottom: 32px;
		}
	}
	&__desk{
		font-size: pxToVW1920(21px);
		font-style: normal;
		font-weight: normal;
		line-height: 140%; /* 29.4px */
		letter-spacing:  pxToVW1920(.084px);
		color: rgb($colorWhite, .8);
		margin-bottom: 32px;
		@media screen and (max-width: 1439px){
			font-size: 14px;
			letter-spacing: 0.56px;
			max-width: 94%;
			margin-bottom: 12px;
		}
		@media screen and (max-width: 991px){
			margin-bottom: 56px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 22.4px */
			letter-spacing: 0.64px;
			max-width: 100%;
		}
		@media screen and (max-width: 767px){
			font-size: 14px;
			letter-spacing: 0.56px;
			margin-bottom: 40px;
		}
	}
	&__nav-top{
		display: flex;
		width: 100%;
		min-height:  pxToVW1920(506px);
		@media screen and (max-width: 1439px){
			min-height: 332px;
		}
		@media screen and (max-width: 991px){
			min-height: 372px;
		}
		@media screen and (max-width: 767px){
			flex-wrap: wrap;
			row-gap: 44px;
		}
	}
	&__nav-wrap{
		display: flex;
		width: 50%;
		gap: 32px;

		&:nth-child(2){
			.footer__nav-item:nth-child(1){
				width: calc(60% - 16px);
			}
			.footer__nav-item:nth-child(2){
				width: calc(40% - 16px);
				.footer__nav-name{
					white-space: nowrap;
				}
			}
			@media screen and (max-width: 767px){
				.footer__nav-item:nth-child(1){
					width: 53%;
					padding-right: 10px;
				}
				.footer__nav-item:nth-child(2){
					width: 47%;
				}
			}
		}

		@media screen and (max-width: 1439px){
			gap: 28px;
		}
		@media screen and (max-width: 991px){
			gap: 0;
			&:first-child{
				width: 46.5%;
				//width: 54%;
				.footer__nav-item:first-child{
					width: 54%;
				}
				.footer__nav-item:nth-child(2){
					width: 46%;
				}
			}
			&:nth-child(2){
				width: 53.5%;
				.footer__nav-item:first-child{
					width: 57%;
				}
				.footer__nav-item:nth-child(2){
					width: 43%;
				}
			}
		}
		@media screen and (max-width: 767px){
			width: 100%;
			&:first-child{
				width: 100%;
				//width: 54%;
				.footer__nav-item:first-child{
					width: 52%;
				}
				.footer__nav-item:nth-child(2){
					width: 48%;
				}
			}
			&:nth-child(2){
				width: 100%;
				.footer__nav-item:first-child{
					width: 52%;
				}
				.footer__nav-item:nth-child(2){
					width: 48%;
				}
			}
		}
		div{
			width: calc(50% - 16px);
			@media screen and (max-width: 1439px){
				width: calc(50% - 14px);
			}
		}

		li{
			margin-bottom:  pxToVW1920(12px);
			line-height: 0;
			@media screen and (max-width: 1439px){
				margin-bottom: 8px;
			}
			@media screen and (max-width: 991px){
				margin-bottom: 12px;
			}
			@media screen and (max-width: 767px){
				margin-bottom: 8px;
			}
		}
	}
	&__nav-name{
		font-size:  pxToVW1920(30px);
		font-style: normal;
		font-weight: 500;
		line-height: 110%;
		text-transform: uppercase;
		margin-bottom:  pxToVW1920(36px);
		color: $colorWhite;
		letter-spacing: normal;
		@media screen and (max-width: 1620px){
			font-size: 22px;
			margin-bottom: 36px;
		}
		@media screen and (max-width: 1439px){
			font-size: 20px;
			margin-bottom: 24px;
		}
		@media screen and (max-width: 991px){
			font-size: 24px;
		}
		@media screen and (max-width: 767px){
			font-size: 20px;
			margin-bottom: 16px;
		}
	}
	&__nav-link{
		color: rgb($colorWhite, .8);
		font-size:  pxToVW1920(21px);
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 29.4px */
		letter-spacing: pxToVW1920(0.84px);
		height:  pxToVW1920(29px);
		@media screen and (max-width: 1439px){
			font-size: 14px;
			height: 19px;
			letter-spacing: 0.56px;
		}
		@media screen and (max-width: 1199px){
			font-size: 12px;
			height: 16.5px;
		}
		@media screen and (max-width: 991px){
			font-size: 16px;
			letter-spacing: 0.64px;
			height: 22.4px;
		}
		@media screen and (max-width: 767px){
			font-size: 14px;
			letter-spacing: 0.56px;
			height: 19px;
		}
	}
	&__nav-bottom ul{
		display: flex;
		flex-direction: row;
		height: 48px;
		align-items: center;
		@media screen and (max-width: 1439px){
			height: 34px;
		}
		@media screen and (max-width: 767px){
			flex-wrap: wrap;
			row-gap: 0;
			height: auto;
			margin-top: 52px;
		}
		li{
			width: 25%;
			margin-bottom: 0;
			&:nth-child(2){
				padding-left: 16px;
			}
			&:nth-child(3){
				width: 30%;
			}
			&:nth-child(4){
				padding-left: 16px;
				width: 20%;
			}
			@media screen and (max-width: 991px){
				&:nth-child(1){
					width: 36%;
				}
				&:nth-child(2){
					width: 41%;
				}
				&:nth-child(3){
					width: calc(23% - 36px);
				}
				&:nth-child(4){
					width: 36px;
					padding-left: 0;
				}
			}
			@media screen and (max-width: 767px){
				width: 50%;
				margin-bottom: 10px;
				&:nth-child(1), &:nth-child(3){
					width: 52%;
					padding-right: 10px;
				}
				&:nth-child(2), &:nth-child(4){
					width: 48%;
					padding-left: 0;
				}
			}

			.icon{
				font-size:  pxToVW1920(24px);
				@media screen and (max-width: 1439px){
					font-size: 16px;
				}
				@media screen and (max-width: 991px){
					font-size: 24px;
				}
				@media screen and (max-width: 767px){
					font-size: 16px;
				}
			}
			.footer__nav-link{
				font-size:  pxToVW1920(18px);
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				letter-spacing: 0.72px;
				margin-bottom: 0;
				display: flex;
				@media screen and (max-width: 1620px){
					font-size: 16px;
				}

				@media screen and (max-width: 1439px){
					font-size: 12px;
				}
				@media screen and (max-width: 991px){
					font-size: 14px;
					letter-spacing: 0.56px;
				}
				@media screen and (max-width: 767px){
					font-size: 12px;
					letter-spacing: 0.48px;
				}
			}
		}
	}
	.no-anim{
		height: auto;
	}

}
.wss_tooltip{
	display: block;
	position: absolute;
	background: $colorWhite;
	border: solid 1px $colorBlack;
	right: 25px;
	bottom: 100%;
	width: 160px;
	color: $colorDarkBlue;
	font-size: 12px;
	line-height: 1.25;
	padding: 5px 10px;
	transition: all .5s ease;
	z-index: -1;
	visibility: hidden;
	opacity: 0;
}
.wss-link{
	position: relative;
	width: fit-content;
	@media screen and (max-width: 767px){
		justify-content: end;
		margin-left: auto;
	}
}
.wss-link:hover .wss_tooltip{
	z-index: 1;
	visibility: visible;
	opacity: 1;
}

.footer-lending{
	.footer__nav-top {
		min-height: auto;
		padding-bottom: 6vw;
	}
	.footer__nav-bottom ul li {
		width: fit-content;
		margin-bottom: 0;
	}
	.footer__nav-bottom ul {
		justify-content: space-between;
	}
}
