.btn {
	display: inline-block;
	font-size:  pxToVW1920(18px);
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing:  pxToVW1920(0.36px);
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	padding:  pxToVW1920(13px)  pxToVW1920(24px);
	transition: all .3 ease;
	border-radius: 199.5px;
	outline: none;
	cursor: pointer;
	background: transparent;
		.btn-text-wrap{
			height:  pxToVW1920(21.6px);
		}
	@media screen and (max-width: 1439px){
		font-size: 12px;
		letter-spacing: 0.24px;
		padding: 9px 16px;
		.btn-text-wrap{
			height: 14.4px;
		}
	}
	@media screen and (max-width: 991px){
		font-size: 14px;
		letter-spacing: 0.32px;
		padding: 10px 20px;
		.btn-text-wrap{
			height: 16.8px;
		}
	}
	@media screen and (max-width: 767px){
		padding: 13px 24px;
		font-size: 12px;
		letter-spacing: 0.24px;
		.btn-text-wrap{
			height: 14.4px;
		}
	}

	&:hover,
	&:active {
		outline: none;
	}

	&:focus {
		outline: none;
	}

	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: .5;
		cursor: auto;
	}
}

.btn-black{
	background: $colorBlack;
	color: $colorWhite;
}
.btn-white{
	color: $colorBlack;
	background: $colorWhite;
}

.btn-outline-white{
	border: 1.5px solid  rgba($colorWhite, 0.40);
	color: $colorWhite;
	@media (hover: hover) {
		&:hover {
			background: $colorWhite;
			color: $colorBlack;
		}
	}
}

.btn-slider{
	background: $colorGray3;
	border-radius: 50%;
	width:  pxToVW1920(48px);
	height:  pxToVW1920(48px);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size:  pxToVW1920(11px);
	color: $colorBlack;
	cursor: pointer;
	transition: transform .35s cubic-bezier(0.215, 0.61, 0.355, 1);

	@media screen and (max-width: 1439px){
		width: 32px;
		height: 32px;
		font-size: 8px;
	}
	@media screen and (max-width: 991px){
		width: 48px;
		height: 48px;
		font-size: 11px;
	}
	@media screen and (max-width: 767px){
		width: 32px;
		height: 32px;
		font-size: 8px;
	}

	&::after{
		display: none;
	}
	.icons-wrap{
		overflow: hidden;
		position: relative;
		width:  pxToVW1920(22px);
		height:  pxToVW1920(20px);
		display: flex;

		@media screen and (max-width: 1439px){
			width: 14px;
			height: 10px;
		}
		@media screen and (max-width: 991px){
			width: 22px;
			height: 20px;
		}
		@media screen and (max-width: 767px){
			width: 14px;
			height: 10px;
		}
		.icon{
			position: absolute;
			top: 50%;
			left: 50%;
			transition: 0.2s ease;
		}
		.icon:first-child{
			transform: translate(-160%, -50%);
		}
		.icon:last-child{
			transform: translate(-50%, -50%);
		}
	}
	@media (hover: hover) {
		&:hover {
			transform: scale(.95);
			.icon:first-child{
				transform: translate(-50%, -50%);
			}
			.icon:last-child{
				transform: translate(70%, -50%);
			}
		}
	}
	&.swiper-button-prev{
		.icons-wrap{
			.icon:first-child{
				transform: translate(160%, -50%);
			}
			.icon:last-child{
				transform: translate(-50%, -50%);
			}
		}
		@media (hover: hover) {
			&:hover {
				.icon:first-child{
					transform: translate(-50%, -50%);
				}
				.icon:last-child{
					transform: translate(-170%, -50%);
				}
			}
		}
	}
}

.link-has-hover{
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: transform .35s cubic-bezier(0.215, 0.61, 0.355, 1);
	span{
		transition: transform .25s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	@media (hover: hover) {
		&:hover {
			span{
				transform: translate(0%, -100%);
			}
		}
	}
}

.btn-hover{
	.btn-text-wrap{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		padding-top: pxToVW1920(1px);
		@media screen and (max-width: 1439px){
			padding-top: 1px;
		}
	}
	span{
		transition: transform .25s cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	@media (hover: hover) {
		&:hover {
			span:not(.btn-text-wrap){
				transform: translate(0%, -100%);
			}
		}
	}
}
